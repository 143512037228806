<template>
  <div class="video-attack main-page">
    <custom-header customBack @onBack="goBack"></custom-header>
    <div v-if="videoAttackStep==1">
      <div class="attack-top">导入信息</div>
      <div class="result-panel">
        <h4>被攻击方</h4>
      </div>
      <div style="height:auto;position:relative">
        <div class="image-box">
          <div class="img-cover">
            <img :src="imgUrl" alt="" class="main-img">
          </div>
        </div>
        <div class="result-panel-header">
          <div class="result-panel">
            <h4>攻击方</h4>
          </div>
          <van-button type="info" class="primary-btn change-video" @click="changeVideo">选择视频</van-button >
        </div>
        <div class="image-box" >
          <div class="img-cover">
            <video :src="attackVideoUrl"  v-if="attackVideoUrl"
              style="object-fit:contain" ref="attackVideoUrlRef" 
              webkit-playsinline playsinline x5-playsinline x5-video-player-type="h5"
              controls autoplay loop class="video">您的浏览器不支持 video 标签。</video>
            <div v-else class="attack-tip" @click="changeVideo">请选择攻击方视频</div>
          </div>
        </div>
        <!--  -->
        <div class="qr-scanner" v-if="attackLoading">
            <div class="box">
              <div class="line"></div>
            </div>
        </div>
      </div>
      <van-button type="info" class="choose-btn" :disabled="attackLoading" @click="createVideo">生成视频</van-button>
    </div>
    <div  v-if="videoAttackStep==2">
      <div class="attack-top top" >视频</div>
      <div class="image-box">
        <div class="img-cover">
          <video :src="videoUrl"
         style="object-fit:contain" ref="videoUrlRef"
           controls autoplay loop x5-playsinline playsinline webkit-playsinline
          x5-video-player-type="h5" x5-video-player-fullscreen="" 
          x5-video-orientation="portraint" 
           class="video">您的浏览器不支持 video 标签。</video>
        </div>
        <div class="ai-tip">视频由AI技术生成</div>
        <!-- <div class="qr-scanner" v-if="attackLoading">
          <div class="box">
            <div class="line"></div>
          </div>
        </div> -->
         <!-- <img :src="imgUrl" alt="" class="result-img-right" v-if="videoUrl"> -->
      </div>
      <van-button type="info" class="choose-btn" :disabled="attackLoading" @click="checkInfo">睿鉴伪造检测</van-button>
    </div>
    <choose-example v-if="chooseShow&&videoAttackStep != 1" :dataList="exampleDataList" @goBack="hideExample" @onDetect="onDetectVideo" type="videoAttack"></choose-example>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";
import ChooseExample from "@/components/ChooseExample.vue";
import { DEFENSE } from '@/remote';
import { Toast,Uploader } from 'vant';
export default {
  name: "video-attack",
  components: {
    "custom-header": CustomHeader,
    "choose-example": ChooseExample,
  },
  data() {
    return {
      imgUrl: 'http://saas.ruijianai.com:9311/mai/image-files/tianjin/tianjinVideo/%E6%96%B9%E6%A1%88%E4%BA%8C%E5%8E%9F%E7%85%A7%E7%89%87.png.jpg',
      loading:false,
      chooseShow:false,
      exampleDataList: [],
      attackVideoUrl: '',//攻击方 视频
      videoAttackStep: 1,
      videoUrl: '',
      attackLoading:false,
    };
  },
  mounted() {
    this.getVideoDemo(12);
  },
  methods: {
    goBack(){
      //第一 步 返回 首页
      if (this.videoAttackStep == 1) {
        setTimeout(() => {
          this.$router.push({
            name: 'Home',
          })
        }, 0)
      }
      //第二步
      if (this.videoAttackStep == 2) {
        this.videoAttackStep = 1;
      }
    },

    // 选择视频
    changeVideo(){
      this.chooseShow = true;
      this.videoAttackStep = 0;
    },

    hideExample() {
      this.chooseShow = false;
      this.videoAttackStep = 1;
    },

    //上传视频开始检测
    onDetectVideo(fileUrl) {
      this.chooseShow = false;
      this.attackVideoUrl = fileUrl; //攻击方 视频
      setTimeout(() => {
        this.$refs.attackVideoUrlRef.play();
      }, 0)
      this.videoAttackStep = 1;
    },

    getVideoDemo(fileType) {
      DEFENSE.getDemo({fileType}).then(res => {
        if (res.code == 200) {
          this.exampleDataList = res.data || [];
        }
      })
    },

    createVideo() {
      if(this.attackVideoUrl){
        this.attackLoading = true;
        let suffixData = this.attackVideoUrl.split(".");
        let suffixType = suffixData[suffixData.length - 1 ];
        let params = {
          video: this.attackVideoUrl,
          videoType: suffixType
        };
        DEFENSE.videoCheck(params).then(res => {
          if (res.code == 200) {
            this.attackLoading = false;
            let fileUrl = (res.data || {}).video;
            this.videoUrl = fileUrl;
            setTimeout(() => {
              this.$refs.videoUrlRef.play();
            }, 0)
            this.videoAttackStep= 2;
          }else{
            //没有检测出人脸
            this.attackLoading = false;
            this.videoAttackStep= 1;
            Toast("请上传清晰的单人正脸视频")
          }
        },error=>{
          this.attackLoading = false;
        })
       }else{
          Toast("请选择攻击视频")
       }
    },
    
    checkInfo() {
      this.$router.push({
        name: "CheckInfo",
        query: {
          video: this.videoUrl,
        }
      });
    },
  },
};
</script>
<style lang="less">
  @import '../../assets/less/videoAttack';
</style>